<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Purchase Requests
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left booking_content_wrapper mb-2"
        cols="12"
        md="12"
      >
        <b-col
          class="content-header-right mb-2 ml-auto"
          cols="12"
          md="12"
        >
          <b-button
            variant="success"
            class="mobile_create_button"
            :to="{ name: 'purchasing-purchase-requests-create' }"
            style="padding: 0.786rem 0.7rem;"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle d-md-block d-none">Create New Purchase Request</span>
            <span class="align-middle d-md-none d-block">New</span>
          </b-button>
        </b-col>
        <b-dropdown
          v-if="isMobile()"
          :text="currentTabTitle"
          variant="outline-primary"
        >
          <b-dropdown-item
            v-for="(tab, index) in pageTabs"
            :key="index"
            @click="changeRoute(tab.param)"
          >
            {{ tab.title }} ({{ tab.count }})
          </b-dropdown-item>
        </b-dropdown>
        <b-tabs
          pills
          class="booking__content pr-tabs"
          :nav-class="[isMobile() ? 'd-none' : '']"
        >
          <b-tab
            :active="$route.params.type == 'open'"
            lazy
            @click="changeRoute('open')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Open <span class="rounded-number">{{ openMyPurchaseRequestCount }}</span></span>
            </template>
            <open-my-purchase-request
              class="mt-2 pt-75"
              :business-entity-options="businessEntityOptions"
              :vendor-options="vendorOptions"
              :requestor-options="requestorOptions"
              @emitCountMyPurchaseRequest="countPurchaseRequests"
            />
          </b-tab>
          <b-tab
            :active="$route.params.type == 'draft'"
            lazy
            @click="changeRoute('draft')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Draft <span class="rounded-number">{{ draftMyPurchaseRequestCount }}</span></span>
            </template>
            <draft-my-purchase-request
              class="mt-2 pt-75"
              :business-entity-options="businessEntityOptions"
              :vendor-options="vendorOptions"
              :requestor-options="requestorOptions"
              @emitCountMyPurchaseRequest="countPurchaseRequests"
            />
          </b-tab>
          <b-tab
            :active="$route.params.type == 'completed'"
            lazy
            @click="changeRoute('completed')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Completed <span class="rounded-number">{{ completedMyPurchaseRequestCount }}</span></span>
            </template>
            <completed-my-purchase-request
              class="mt-2 pt-75"
              :business-entity-options="businessEntityOptions"
              :vendor-options="vendorOptions"
              :requestor-options="requestorOptions"
              @emitCountMyPurchaseRequest="countPurchaseRequests"
            />
          </b-tab>
          <b-tab
            :active="$route.params.type == 'cancelled'"
            lazy
            @click="changeRoute('cancelled')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Cancelled <span class="rounded-number">{{ cancelledMyPurchaseRequestCount }}</span></span>
            </template>
            <cancelled-my-purchase-request
              class="mt-2 pt-75"
              :business-entity-options="businessEntityOptions"
              :vendor-options="vendorOptions"
              :requestor-options="requestorOptions"
              @emitCountMyPurchaseRequest="countPurchaseRequests"
            />
          </b-tab>
          <b-tab
            :active="$route.params.type == 'all-requests'"
            lazy
            @click="changeRoute('all-requests')"
          >
            <template #title>
              <span class="d-none d-sm-inline">All My Requests <span class="rounded-number">{{ allMyPurchaseRequestCount }}</span></span>
            </template>
            <all-my-purchase-request
              class="mt-2 pt-75"
              :business-entity-options="businessEntityOptions"
              :vendor-options="vendorOptions"
              :requestor-options="requestorOptions"
              @emitCountMyPurchaseRequest="countPurchaseRequests"
            />
          </b-tab>
          <b-tab
            :active="$route.params.type == 'in-my-pending-sign'"
            lazy
            @click="changeRoute('in-my-pending-sign')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Awaiting My Signature <span class="rounded-number">{{ mySignCount }}</span></span>
            </template>
            <in-my-sign
              class="mt-2 pt-75"
              :business-entity-options="businessEntityOptions"
              :vendor-options="vendorOptions"
              :requestor-options="requestorOptions"
              @emitCountMyPurchaseRequest="countPurchaseRequests"
            />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BButton, BCol, BTab, BTabs, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AllMyPurchaseRequest from './all-my-purchase-request/AllMyPurchaseRequest.vue'
import CompletedMyPurchaseRequest from './completed-my-purchase-request/CompletedMyPurchaseRequest.vue'
import CancelledMyPurchaseRequest from './cancelled-my-purchase-request/CancelledMyPurchaseRequest.vue'
import DraftMyPurchaseRequest from './draft-my-purchase-request/DraftMyPurchaseRequest.vue'
import OpenMyPurchaseRequest from './open-my-purchase-request/OpenMyPurchaseRequest.vue'
import InMySign from './my-sign-requests/InMySign.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTab,
    BTabs,
    BDropdown,
    BDropdownItem,

    AllMyPurchaseRequest,
    DraftMyPurchaseRequest,
    CompletedMyPurchaseRequest,
    CancelledMyPurchaseRequest,
    OpenMyPurchaseRequest,
    InMySign,
  },
  data() {
    return {
      mySignCount: 0,
      allMyPurchaseRequestCount: 0,
      completedMyPurchaseRequestCount: 0,
      cancelledMyPurchaseRequestCount: 0,
      draftMyPurchaseRequestCount: 0,
      openMyPurchaseRequestCount: 0,
      currentTabTitle: '',
      pageTabs: [
        {
          title: 'Open',
          param: 'open',
          count: 0,
        },
        {
          title: 'Draft',
          param: 'draft',
          count: 0,
        },
        {
          title: 'Completed',
          param: 'completed',
          count: 0,
        },
        {
          title: 'Cancelled',
          param: 'cancelled',
          count: 0,
        },
        {
          title: 'All My Requests',
          param: 'all-requests',
          count: 0,
        },
        {
          title: 'Awaiting My Signature',
          param: 'in-my-pending-sign',
          count: 0,
        },
      ],
      businessEntityOptions: [],
      vendorOptions: [],
      requestorOptions: [],
    }
  },
  watch: {
    $route() {
      const param = this.$route.params.type
      const currentTab = this.pageTabs.find(o => o.param === param)
      if (currentTab) {
        this.currentTabTitle = `${currentTab.title} (${currentTab.count})`
      }
    },
  },
  beforeMount() {
    this.countPurchaseRequests()
    this.$http.get('purchase/purchase-request/my-pr/extra-options')
      .then(response => {
        this.businessEntityOptions = response.data.businessEntityOptions ?? []
        this.vendorOptions = response.data.vendorOptions ?? []
        this.requestorOptions = response.data.requestorOptions ?? []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    changeRoute(param) {
      this.$router.replace({
        params: {
          type: param,
        },
        query: {},
      })
    },
    countPurchaseRequests() {
      this.$http.get('purchase/purchase-request/counts/own')
        .then(response => {
          this.allMyPurchaseRequestCount = response.data.purchaseRequestCount
          this.completedMyPurchaseRequestCount = response.data.completedMyPurchaseRequestCount
          this.cancelledMyPurchaseRequestCount = response.data.cancelledMyPurchaseRequestCount
          this.draftMyPurchaseRequestCount = response.data.draftMyPurchaseRequestCount
          this.openMyPurchaseRequestCount = response.data.openMyPurchaseRequestCount
          this.mySignCount = response.data.mySignCount

          this.pageTabs.forEach(element => {
            if (element.title === 'Open') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.openMyPurchaseRequestCount
            } else if (element.title === 'Draft') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.draftMyPurchaseRequestCount
            } else if (element.title === 'Completed') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.completedMyPurchaseRequestCount
            } else if (element.title === 'Cancelled') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.cancelledMyPurchaseRequestCount
            } else if (element.title === 'All My Requests') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.allMyPurchaseRequestCount
            } else if (element.title === 'Awaiting My Signature') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.mySignCount
            }
          })

          const param = this.$route.params.type

          const currentTab = this.pageTabs.find(o => o.param === param)
          if (currentTab) {
            this.currentTabTitle = `${currentTab.title} (${currentTab.count})`
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style scoped>
  .rounded-number {
    height: 24px;
    width: 24px;
    background-color: #FFFFFF;
    border-radius: 50%;
    color:#104D9D;
  }
</style>
