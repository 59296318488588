<template>
  <b-dropdown-form
    class="py-1 dropdown-filter-form-n"
  >
    <b-row>
      <b-col cols="10">
        <p>Filters</p>
      </b-col>
      <b-col cols="2">
        <feather-icon
          icon="XIcon"
          size="16"
          class="align-right"
          @click="$emit('emitHideFilter')"
        />
      </b-col>
    </b-row>
    <b-form-group label="Entity">
      <b-form-checkbox
        v-for="option in entityOptions"
        :key="option._id"
        v-model="entityValue"
        :value="option._id"
        name="flavour-3a"
      >
        {{ option.name }}
      </b-form-checkbox>
    </b-form-group>
    <hr class="dividerHR filter-divider">
    <b-form-group
      label="Created At"
    >
      <b-input-group>
        <flat-pickr
          v-model="createdAtFilterValue"
          class="form-control"
          placeholder="Select Date Range"
          :config="flatPickrConfig"
        />
        <b-input-group-append is-text>
          <feather-icon
            icon="CalendarIcon"
            class="cursor-pointer"
            data-toggle
            size="18"
          />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <hr class="dividerHR filter-divider">
    <b-form-group
      label="Requestor"
      @click.native.stop
    >
      <v-select
        v-model="requestorValue"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        multiple
        placeholder="Select an option"
        :options="requestorOptions"
        :clearable="false"
        :reduce="name => name._id"
      />
    </b-form-group>
    <hr class="dividerHR filter-divider">
    <b-form-group label="Stage">
      <b-form-checkbox
        v-for="option in stageOptions"
        :key="option.code"
        v-model="stageFilterValue"
        :value="option.code"
        name="flavour-3a"
      >
        {{ option.title }}
      </b-form-checkbox>
    </b-form-group>
    <hr class="dividerHR filter-divider">
    <b-form-group label="Invoices Attached">
      <b-form-checkbox
        v-for="option in invoiceOptions"
        :key="option.code"
        v-model="invoiceFilterValue"
        :value="option.code"
        name="flavour-3a"
        @input="makeCheckboxToRadio()"
      >
        {{ option.title }}
      </b-form-checkbox>
    </b-form-group>
    <hr class="dividerHR filter-divider">
    <b-form-group
      label="Vendor"
      @click.native.stop
    >
      <v-select
        v-model="vendorValue"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="companyName"
        multiple
        placeholder="Select from list"
        :options="vendorOptions"
        :clearable="false"
        :reduce="name => name._id"
      />
    </b-form-group>
    <b-button
      variant="outline-primary"
      size="sm"
      class="mr-1"
      @click="clearFilter"
    >
      Clear All
    </b-button>
    <b-button
      variant="primary"
      size="sm"
      @click="applyFilter"
    >
      Apply Filter
    </b-button>
  </b-dropdown-form>
</template>

<script>
import {
  BDropdownForm, BFormGroup, BButton, BRow, BCol, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BButton,
    BDropdownForm,
    BFormGroup,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    vSelect,
    flatPickr,
  },
  props: {
    stageFilter: {
      type: [Array, null],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    entity: {
      type: [Array, null],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    vendor: {
      type: [Array, null],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    createdAtFilter: {
      type: [String, null],
      default: null,
    },
    invoiceFilter: {
      type: [Array, null],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    stageOptions: {
      type: Array,
      required: true,
    },
    invoiceOptions: {
      type: Array,
      required: true,
    },
    vendorOptions: {
      type: Array,
      required: true,
    },
    entityOptions: {
      type: Array,
      required: true,
    },
    requestor: {
      type: [Array, null],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    requestorOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      createdAtFilterValue: this.createdAtFilter,
      stageFilterValue: JSON.parse(JSON.stringify(this.stageFilter)) || [],
      vendorValue: JSON.parse(JSON.stringify(this.vendor)) || [],
      entityValue: JSON.parse(JSON.stringify(this.entity)) || [],
      requestorValue: JSON.parse(JSON.stringify(this.requestor)) || [],
      invoiceFilterValue: JSON.parse(JSON.stringify(this.invoiceFilter)) || [],
      flatPickrConfig: {
        wrap: true, mode: 'range', dateFormat: 'd/m/Y', enableTime: false, static: true, disableMobile: true,
      },
    }
  },
  watch: {
    createdAtFilter: {
      immediate: true,
      handler(val) {
        this.createdAtFilterValue = val
      },
    },
    stageFilter: {
      immediate: true,
      handler(val) {
        this.stageFilterValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
    invoiceFilter: {
      immediate: true,
      handler(val) {
        this.invoiceFilterValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
    vendor: {
      immediate: true,
      handler(val) {
        this.vendorValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
    entity: {
      immediate: true,
      handler(val) {
        this.entityValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
    requestor: {
      immediate: true,
      handler(val) {
        this.requestorValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
  },
  methods: {
    makeCheckboxToRadio() {
      const lastSelected = this.invoiceFilterValue.slice(-1)[0]
      if (lastSelected) {
        this.invoiceFilterValue = this.invoiceFilterValue.filter(code => code === lastSelected)
      }
    },
    showAgainDropdown() {
      if (this.createdAtFilterValue) {
        this.$emit('emitShowFilter')
      }
    },
    applyFilter() {
      this.$emit('update:createdAt', this.createdAtFilterValue)
      this.$emit('update:stageFilter', this.stageFilterValue)
      this.$emit('update:vendor', this.vendorValue)
      this.$emit('update:entity', this.entityValue)
      this.$emit('update:requestor', this.requestorValue)
      this.$emit('update:invoiceFilter', this.invoiceFilterValue)

      this.$emit('emitHideFilter')
    },
    clearFilter() {
      this.createdAtFilterValue = ''
      this.stageFilterValue = []
      this.vendorValue = []
      this.entityValue = []
      this.requestorValue = []
      this.invoiceFilterValue = []
      this.$emit('update:createdAt', this.createdAtFilterValue)
      this.$emit('update:stageFilter', this.stageFilterValue)
      this.$emit('update:vendor', this.vendorValue)
      this.$emit('update:entity', this.entityValue)
      this.$emit('update:requestor', this.requestorValue)
      this.$emit('update:invoiceFilter', this.invoiceFilterValue)

      this.$emit('emitHideFilter')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
